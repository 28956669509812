<template>
  <div class="strokes-page">
    <b-container fluid>
      <b-row>
        <b-col>
          <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
        </b-col>
      </b-row>
      <b-row class="justify-content-md-center">
        <b-col>
          <nav aria-label="Page Navigation">
            <ul class="pagination pagination-sm justify-content-center">
              <li class="page-item" :class="strokePage.currentPage == 0 ? 'disabled' : 'cursor-pointer'"><a
                  class="page-link" @click="pagePrev()">Previous</a></li>
              <li v-for="pg in pages" :key="pg" class="page-item"
                :class="strokePage.currentPage == (pg - 1) ? 'active' : ''">
                <a class="page-link cursor-pointer" @click="jumpToPage(pg - 1)">{{ pg }}</a>
              </li>
              <li class="page-item"
                :class="strokePage.currentPage < (strokePage.totalPages - 1) ? 'cursor-pointer' : 'disabled'"><a
                  class="page-link" @click="pageNext()">Next</a></li>
            </ul>
          </nav>
        </b-col>
      </b-row>
      <b-row class="h5 justify-content-md-center">
        <b-col>
          Recorded By
        </b-col>
        <b-col>
          Recorded At
        </b-col>
        <b-col>
          Label
        </b-col>
      </b-row>
      <b-row>
        <b-col class="grid-striped">
          <b-row v-for="item in strokePage.page" :key="item.guid">
            <b-col @click="viewStroke(item.guid)">{{ item.user.fname }}</b-col>
            <b-col @click="viewStroke(item.guid)">{{ item.created | moment("MMM D, YYYY HH:mm:ss") }}</b-col>
            <b-col class="text-left">
              <span>
                <input type="text" v-if="edit == item.guid" :value="item.label" maxlength="45"
                  @blur="save(item.guid, $event);" @keyup.enter="save(item.guid, $event);" @keyup.esc="esc($event);"
                  v-focus="" />
                <span @click="() => { edit = item.guid; oldLabel = item.label }" v-else>
                  {{ item.label }}
                </span>
                <span class="float-right">
                  <b-icon-pencil-square @click="() => { edit = item.guid; oldLabel = item.label }" v-if="edit != item.guid"></b-icon-pencil-square>
                  <b-icon-trash @click="() => {deleteStroke(item.guid)}" class="ml-1" variant="danger"></b-icon-trash>
                </span>
              </span>
            </b-col>
          </b-row></b-col>
      </b-row>
      <b-row>
        <b-col class="text-right"><b-link href="#" @click="downloadAll($event)">&pi;</b-link></b-col>
      </b-row>
    </b-container>
  </div>
</template>
  
<script>
export default {
  name: "Strokes",
  components: {
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus()
      }
    }
  },
  data() {
    return {
      strokePage: {},
      pages: [],
      edit: false,
      oldLabel: '',
      showPreEllipses: false,
      showPostEllipses: true,
      breadcrumbs: [
        {
          text: 'Home',
          to: { path: '/' }
        },
        {
          text: 'Strokes',
          active: true
        }
      ]
    };
  },
  methods: {
    save(guid, event) {
      // if(event.target.value){
      if (event.code && event.code == 'Enter') {
        //Enter Event
        this.keyenter = true;
        this.saveLabel(guid, event.target.value);
      } else {
        //Blur Event
        if (this.keyenter || this.keyesc) {
          this.keyenter = false;
          this.keyesc = false;
        } else {
          this.saveLabel(guid, event.target.value);
        }
      }
      // }
    },
    downloadAll: function(event){
      event.preventDefault()
      this.$remote.fetchAllStrokesBlob().then(response => {
        if (response.status == 200) {
          const blob = new Blob([response.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "allstrokes";
          link.click();
          URL.revokeObjectURL(link.href);
        } else {
          this.$router.push("error");
        }
      });
    },
    async deleteStroke(guid){     
      try {
        const response = await this.$remote.deleteStroke(
          guid
        );

        if (response.status != 200) {
          this.$router.push("error");
        }
        this.jumpToPage(this.strokePage.currentPage)
      } catch (error) {
        this.$router.push("error");
      }
    },
    esc(event) {
      this.keyesc = true;
      event.target.value = this.oldValue;
      this.edit = '';
    },
    saveLabel: async function (guid, label) {
      try {
        const response = await this.$remote.saveLabel(
          guid,
          label
        );

        if (response.status != 200) {
          this.$router.push("error");
        }
        this.strokePage.page.forEach(item => {
          if (item.guid === guid) {
            item.label = label
          }
        })
        this.edit = ''
      } catch (error) {
        this.$router.push("error");
      }
    },
    pageNext() {
      //Go to next page.
      this.jumpToPage(this.strokePage.currentPage + 1)
    },
    pagePrev() {
      //Go to pev page.
      this.jumpToPage(this.strokePage.currentPage - 1)
    },
    jumpToPage(page) {
      if (page < 0 || page > (this.strokePage.totalPages - 1)) {
        //Going to invalid page, do nothing.
      } else {
        this.fetchServerPage('', page)
        //this.strokePage.currentPage = page
        //this.drawPagination()
      }
    },
    viewStroke(guid) {
      this.$router.push({ path: '/stroke/' + guid })
    },
    drawPagination() {

      var start = 1

      if (this.strokePage.currentPage - 5 > 1) {
        start = this.strokePage.currentPage - 5
      }

      var end = this.strokePage.totalPages
      if (this.strokePage.currentPage + 5 < this.strokePage.totalPages) {
        end = this.strokePage.currentPage + 5
      }

      this.pages = []
      for (var i = start; i <= end; i++) {
        this.pages.push(i)
      }
    },
    fetchServerPage: async function (query, page) {
      this.$store.commit("setBusy");
      const response = await this.$remote.getStrokes(query, page);
      this.$store.commit("setUnbusy")
      if (response.status == 200) {
        this.strokePage = response.data
        this.$store.commit("setStrokePage", response.data)
        this.drawPagination()
      } else {
        this.$router.push("error")
      }
    }
  },
  created: async function () {

    if (this.$store.state.strokePage.page?.length > 0) {
      this.strokePage = this.$store.state.strokePage
      this.drawPagination()
    } else {
      this.fetchServerPage('', 0)
    }



  }
};
</script>
<style lang="less">
.grid-striped .row:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.grid-striped .row:hover {
  background-color: yellow;
  cursor: pointer;
}
</style>