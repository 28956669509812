<template>
  <div class="login-page">
    <b-container fluid>
      <b-row class="justify-content-md-center">
        <b-col col lg="4">
          <div>
            <div class="page-title">Login</div>
            <div class="denied" v-if="denied">
              Invalid email/password, please try again.
            </div>
            <b-form @submit="onSubmit">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="stacked"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.username.$model"
                  :state="validateState($v.username)"
                  type="text"
                  autocomplete="username"
                  placeholder="Enter email"
                  required
                  trim
                  autofocus
                />
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label-for="input-3"
                class="stacked"
              >
                <b-form-input
                  id="input-3"
                  v-model="$v.password.$model"
                  :state="validateState($v.password)"
                  type="password"
                  placeholder="Password"
                  required
                  trim
                  autocomplete="current-password"
                  minlength="4"
                />
              </b-form-group>
              <b-form-group id="input-group-4" label-for="input-4">
                <b-form-checkbox
                  id="input-4"
                  v-model="remember"
                  name="input-4"
                  value="remember"
                  unchecked-value="dont_remember"
                >
                  Remember this computer.
                </b-form-checkbox>
              </b-form-group>
              <div class="form-command">
                <b-button type="submit" variant="primary"> Login </b-button>
              </div>
            </b-form>
          </div>
          <div class="login">
            <div><b-link to="ForgotPassword">Forgot Password</b-link></div>
            <div>
              Don't have an account: <b-link to="Register">Register</b-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "Register",
  components: {},
  data() {
    return {
      username: null,
      password: null,
      denied: false,
      remember: "dont_remember",
    };
  },
  validations: {
    username: {
      required,
      //email,
    },
    password: {
      required,
    },
  },
   async created() {
      this.$store.commit("setBusy");
      this.$store.commit("deleteAuth");
      var hasCookie = this.$cookies.isKey(process.env.VUE_APP_COOKIE);
     
      if (hasCookie) {
        //Get the cookie value
        var cookie = this.$cookies.get(process.env.VUE_APP_COOKIE)

        //Verify Cookie
        this.$store.commit("setAuth", cookie);
        this.$remote.renew().then(response=>{
          if(response.data.lifespan == null){
            this.$cookies.set(process.env.VUE_APP_COOKIE, response.data,0);
          }else{
            var now = Date.now();
            now = now + response.data.lifespan;
            response.data.expires = now;
            this.$cookies.set(process.env.VUE_APP_COOKIE, response.data, new Date(now));
          }
           this.$store.commit("setAuth", response.data);
           this.sendOnMerryWay();
        }).catch(() => {
            this.$cookies.remove(process.env.VUE_APP_COOKIE);           
        }).finally(() => {
          this.$store.commit("setUnbusy");
        });
      }else{
        this.$store.commit("setUnbusy");
      }      
    },
  methods: {
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    sendOnMerryWay(){ 
      //Test if we should redirect to an original resource.
      if (this.$store.state.path) {
        var to = this.$store.state.path.path;
        this.$store.commit("deletePath");
        this.$router.push(to);
      } else {
        this.$router.push("home");
      }
    },
    onSubmit: async function (event) {
      event.preventDefault();
      //Validation
      this.$v.username.$touch();
      this.$v.password.$touch();
      if (this.$v.username.$anyError || this.$v.password.$anyError) {
        return false;
      }
      this.$store.commit("setBusy");

      var errhandler = response =>{       
        //401 should just reject... Anything else goes to error page.
        if (response.status != 401) {
          this.$router.push("error");
        }
      };

      this.$remote.login(this.username,this.password,this.remember === "remember",errhandler).then(response => {
        if(response.data.lifespan == null){
          this.$cookies.set(process.env.VUE_APP_COOKIE, response.data,0);
        }else{
          var now = Date.now();
          now = now + response.data.lifespan;
          response.data.expires = now;
          this.$cookies.set(process.env.VUE_APP_COOKIE, response.data, new Date(now));
        }
        this.$store.commit("setAuth", response.data);
        this.$store.commit("setUnbusy");
        this.sendOnMerryWay();
      }).catch(() => {
          //This should happen when a 401
          this.denied = true;
          this.password = null;
        });
    },
  },
};
</script>
<style lang="less">
.login-page {
  .form-command {
    margin-top: 10px;
  }

  .login {
    margin-top: 10px;
    font-size: small;
  }
  .stacked {
    margin-bottom: 5px;
  }
  .denied {
    color: darkred;
    font-weight: bold;
  }
  .input-group-text {
    height: 100% !important;
  }
}
</style>