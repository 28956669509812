<template>
  <div class="error-page">
    <b-container fluid>
      <b-row class="justify-content-md-center">
        <b-col col lg="4">
          <div>
            <div>
              <div class="page-title page-title-error">Error!</div>
              An unexpected error has occured. Please try your request again
              later. If this problem persists please contact us through our
              website.
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Error",
  components: {},
  data() {
    return {};
  },
  methods: {},
  created: function(){
    
  }
};
</script>
<style lang="less">
.error-page {
  .page-title-error {
    color: darkred;
    font-weight: bold;
  }
}
</style>