<template>
    <div class="play-page">
      <b-container fluid>
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
        <b-col class="text-center page-title">
          Put into Practice
        </b-col>
      </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            Record your golf round and see how you have improved over time.
          </b-col>             
        </b-row> 
        <b-row class="mt-4">
          <b-col sm="12" lg="4" class="font-weight-bold">Enter Round Details:</b-col>
          <b-col sm="12" lg="4">
            <b-form-datepicker 
              id="datepicker" 
              v-model="recordDate" 
              today-button
              close-button
              size="sm">
            </b-form-datepicker>
          </b-col>
          <b-col sm="12" lg="3">
            <b-form-input id="score" placeholder="# of Putts" v-model="recordScore" type="number" size="sm"></b-form-input>
          </b-col>
          <b-col cols="1">
            <b-button @click="addGame()" variant="success" size="sm">Save</b-button>
          </b-col>
        </b-row>  
        <b-row class="mt-4">
          <b-col>
            <highcharts class="hc" :options="gameChartOptions" ref="chart2"></highcharts>
          </b-col>
          <b-col>
            <b-table striped hover :items="games" :fields="gameFields"></b-table>
          </b-col>          
        </b-row>  
      </b-container>
    </div>
  </template>
  
  <script>
  export default {
    name: "Play",
    components: {},
    data() {
      return {
        recordDate: new Date(),
        recordScore: null,
        gameFields: [          
          { key: "played", formatter: "formatDateAssigned" },
          { key: "putts" },
        ],
        games : [                  
        ],
        breadcrumbs: [
          {
            text: 'Home',
            to: { path: '/' }
          },
          {
            text: 'Put into Practice',
            active: true
          }
        ],
        gameChartOptions: {   
          title: {
            text:'Putts over time'
          },       
          xAxis: {
            visible: true,
            type:'datetime',
            endOnTick: true,
            labels: {
              format: '{value:%b %e, %Y}',
              rotation: 70,
              align: 'left'
            },
          },
          yAxis:{
            min: 0,
            visible: true,
          },
          chart: {
              type: 'spline',
          },
          tooltip: {
            headerFormat: '',
            pointFormat: '<b>{point.x:%b %e, %Y}</b><br>{point.y}'
          },
          series: []
        },
      };
    },
    methods: {
      formatDateAssigned(value) {
        return this.$moment(value).format("MMM D, YYYY")
      },
      displayData(){
        this.gameChartOptions.series = []
        var gameSeries = {data: [],animation: true, showInLegend:false,}
        this.games.slice().reverse().forEach(game => gameSeries.data.push([game.played.getTime(), game.putts]))
        this.gameChartOptions.series.push(gameSeries);
      },
      addGame(){
        //TODO real validation
        if(this.recordDate && this.recordScore){
          var game = {played:new Date(this.recordDate), putts: parseInt(this.recordScore)}
          this.$remote.saveGame(game.played.getTime(),game.putts)
          this.games.unshift(game)
          this.displayData()
          this.recordDate = new Date()
          this.recordScore = null
        }
      }
    },
    created: async function(){
      this.$store.commit("setBusy");

      const response = await this.$remote.getGames();

      this.$store.commit("setUnbusy");

      if (response.status == 200) {
        this.games = []
        response.data.rounds.forEach(game => this.games.push({"played": new Date(game.played), "putts":game.putts}));
        this.displayData()
      } else {
        this.$router.push("error");
      }
    }
  };
  </script>
  <style lang="less">  
  </style>