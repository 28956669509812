import axios from 'axios'
import store from '../store/index'
import router from '../router/index'

const AXIOS = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  headers: {
    'Access-Control-Allow-Origin': process.env.VUE_APP_SITE_HOST
  }
});

AXIOS.interceptors.response.use(
  res => res,
  err => {
    return err.response
  }
);


export default {
  constructAuthConfig() {
    var token = 'Bearer ' + store.state.auth?.token;

    var config = {
      headers: {
        Authorization: token
      }
    }
    return config
  },
  constructRemoteConfig(successCallback, errorCallback, blocking){
    var config = {};

    if(!successCallback){
      throw 'Success Callback is requried!';
    }else if(typeof successCallback != "function"){
      throw 'Success callback of unsupported type';
    }

    config.success = successCallback;

    if(blocking){
      if( typeof blocking != "boolean"){
        throw 'Blocking must be of type boolean'
      }else{
        config.blocking = blocking;
      }      
    }

    if(errorCallback){

      if(typeof errorCallback == "boolean"){
        config.blocking = errorCallback;
      }else if(typeof errorCallback == "function"){
        config.error = errorCallback;
      }else{
        throw 'Error callback of unsupported type';
      }

    }
    
    return config;
  
  },
  handleError(error, callback){
    store.commit("setUnbusy");

    //Default error handling 401,403. Everything else can be custom handled.
    if(error.status == 401){
      router.push("login");
    }else if(error.status == 403){
      router.push("forbidden");
    }
    if(callback){
      //Custom error handler for any response not 200, 401, or 403
      callback(error);
    }else{
      //No custom error handling, use the default error page. 
      router.push("error");
    }
  },
  handleResponse(response, callback){
    //This callback is because it seems there is an error in the AXIOS documentaton.
    //.then() should only get 200 responses... But instead its getting everything
    //EXCEPT errors like connection error.
    //So I created this to alleviate some of the boiler plate.
    if(response.status == 200){
      return true;
    }else{
      this.handleError(response,callback);
    }
  },

  //-----------------------------------------------
  //----------------- API CALLS -------------------
  //-----------------------------------------------
  
  // Public API's
  requestReset(username) {
    return new Promise((resolve, reject) => {
      AXIOS.post('/public/auth/reset', { 'email': username }).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error);
          reject(error);
        });
    });
  },
  login(username, password, remember, errorCallback) {
    if (!remember) {
      remember = false;
    }
    return new Promise((resolve, reject) => {
      AXIOS.post('/public/auth/signin', { 'username': username, 'password': password, 'remember': remember }).then(response => 
        {
          if(this.handleResponse(response,errorCallback)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error);
          reject(error);
        });
    });
  },
  register(fname,lname, username, password, recaptcha,config) {
    return new Promise((resolve, reject) => {
      AXIOS.post('/public/auth/signup', { 'fname': fname,'lname': lname, 'captcha': recaptcha, 'username': username, 'password': password }).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error,config.error);
          reject(error);
        });
    });
  },
  // Private API's
  //// Game API's
  getGames(){
     return new Promise((resolve, reject) => {
      AXIOS.get('/private/rounds', this.constructAuthConfig()).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error);
          reject(error);
        });
    });
  },
  saveGame(timestamp,score){
    return new Promise((resolve, reject) => {
      AXIOS.post('/private/rounds/add?played='+timestamp + '&putts='+score,{}, this.constructAuthConfig()).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error);
          reject(error);
        });
    });
  },

  //// Stroke API's
  getStrokes(filter, page) {
    return new Promise((resolve, reject) => {
      AXIOS.get('/private/strokes/list?q='+filter+'&page='+page, this.constructAuthConfig()).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error);
          reject(error);
        });
    });
  },
  getStroke(id){
    return new Promise((resolve, reject) => {
      AXIOS.get('/private/strokes/stroke/'+id, this.constructAuthConfig()).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error);
          reject(error);
        });
    });
  },
  deleteStroke(id){
    return new Promise((resolve, reject) => {
      AXIOS.post('/private/strokes/stroke/delete/' + id, {}, this.constructAuthConfig()).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error);
          reject(error);
        });
    });
  },
  saveLabel(guid,label){
    return new Promise((resolve, reject) => {
      AXIOS.post('/private/strokes/stroke/label/save', { 'guid': guid, 'label': label }, this.constructAuthConfig()).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error);
          reject(error);
        });
    });
  },
  fetchAllStrokesBlob(){
    var config = this.constructAuthConfig();
    config.responseType= "blob";

    return AXIOS.get('/private/strokes/download', config);
  },

  //// Analysis API's
  getAnalysis(){
    return new Promise((resolve, reject) => {
      AXIOS.get('/private/strokes/analysis', this.constructAuthConfig()).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error);
          reject(error);
        });
    });
  },


  //// Account and User API's
  activateAccount() {
    return new Promise((resolve, reject) => {
      AXIOS.put('/private/auth/activate', {}, this.constructAuthConfig()).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error);
          reject(error);
        });
    });
  },
  validateToken(config) {
    return new Promise((resolve, reject) => {
      AXIOS.get('/private/auth/validate?otp=' + store.state.auth.token).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error,config.error);
          reject(error);
        });
    });
  },
  reset(password ) {
    return new Promise((resolve, reject) => {
      AXIOS.put('/private/auth/passwd', { 'password': password }, this.constructAuthConfig()).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error);
          reject(error);
        });
    });
  },
  validate() {
    return new Promise((resolve, reject) => {
      AXIOS.get('/private/auth/validate', {}, this.constructAuthConfig()).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error);
          reject(error);
        });
    });
  },
  renew() {
    return new Promise((resolve, reject) => {
      AXIOS.put('/private/auth/renew', {}, this.constructAuthConfig()).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error);
          reject(error);
        });
    });
  },
  logout() {
    return new Promise((resolve, reject) => {
      AXIOS.put('/private/auth/logout', {}, this.constructAuthConfig()).then(response => 
        {
          if(this.handleResponse(response)){
            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          this.handleError(error);
          reject(error);
        });
    });
  },



}