<template>
  <div class="reset-page">
    <b-container fluid>
      <b-row class="justify-content-md-center">
        <b-col col lg="4">
          <div class="page-title">Reset Password</div>
          <div v-if="!submitted && !hasbad">
            <div>
              <b-form @submit="onSubmit">
                <b-form-group id="input-group-3" label-for="input-3">
                  <b-form-input
                    id="input-3"
                    v-model="$v.password.$model"
                    :state="validateState($v.password)"
                    type="password"
                    placeholder="Password"
                    required
                    trim
                    autofocus
                    minlength="4"
                    pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
                    title="Must contain at least one number and one letter and be at least 8 characters long"
                  />
                  <div class="passhelp"> 
                    Must contain at least one number and one letter and be at least 8 characters long
                  </div>
                </b-form-group>

                <b-form-group
                  id="input-group-4"
                  label-for="input-4"
                  class="stacked"
                >
                  <b-form-input
                    id="input-4"
                    v-model="$v.cpassword.$model"
                    :state="validateState($v.cpassword)"
                    type="password"
                    placeholder="Confirm Password"
                    required
                    trim
                  />
                </b-form-group>
                <div class="form-command">
                  <b-button type="submit" variant="primary"> Submit </b-button>
                </div>
              </b-form>
            </div>
            <div class="login">
              Already have an account: <b-link to="Login">Login</b-link>
            </div>
          </div>
          <div v-if="submitted && !hasbad">
            <div>
              <div class="thanks">Your password has been updated!</div>
              Please keep this password secure, and thank you for using Umangia.
            </div>
            <div class="login">
              Return to: <b-link to="Login">Login</b-link>
            </div>
          </div>
          <div v-if="!submitted && hasbad">
            <div>
              <div class="thanks">Failed to update password!</div>
              We're sorry, but we were unable to update your password. The most
              likely cause of this is too much time passed since you requested
              the password reset. Please return to the login page and follow the
              forgot password link again.
            </div>
            <div class="login">
              Return to: <b-link to="Login">Login</b-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { validPassword } from "../../validators/index";
export default {
  name: "Reset",
  components: {},
  data() {
    return {
      otp: null,
      username: null,
      password: null,
      cpassword: null,
      hasCap: false,
      hasLwr: false,
      hasNum: false,
      hasSpec: false,
      hasLen: false,
      submitted: false,
      hasbad: false,
    };
  },
  methods: {
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    onSubmit: async function (event) {
      event.preventDefault();
      //Validation
      this.$v.password.$touch();
      this.$v.cpassword.$touch();
      if (
        this.$v.password.$anyError ||
        this.$v.cpassword.$anyError ||
        this.robot
      ) {
        return false;
      }

      var config = {
        error: response =>{
          if (response.status == 401) {
            this.denied = true;
          }
        }
      };

      this.$store.commit("setBusy");
      this.$remote.reset(this.password, this.otp,config).then(() => {
        this.submitted = true;
      }).finally(()=>{
        this.$store.commit("setUnbusy");
      });
    },
  },
  watch: {
    password: function (val) {
      this.hasCap = /[a-zA-Z]/.test(val);
      this.hasNum = /[0-9]/.test(val);
      this.hasLen = val.length >= 8;
    },
  },
  validations: {
    password: {
      required,
      validPassword,
      minLength: minLength(8),
    },
    cpassword: { required, sameAsPassword: sameAs("password") },
  },
  created: async function () {
    if (this.$route.query.token) {
      this.$store.commit("setBusy");
      this.$store.commit("setAuth", {token: this.$route.query.token});
      
       var config = {
        error: () =>{
          this.hasbad = true;
        }
      };
      
      this.$remote.validateToken(config).then(()=>{
        this.activated = true;
        this.otp = this.$route.query.token;
      }).finally(()=>{
        this.$store.commit("setUnbusy");
      });
      
    } else {
      this.$router.push("error");
    }
  },
};
</script>
<style lang="less">
.reset-page {
  .passhelp {
    padding: 10px 5px 10px 5px;
    font-size: smaller;
    font-weight: bold;
    .status {
      padding: 3px;
      border: 1px solid grey;
      .red {
        color: red;
      }
      .green {
        color: green;
      }
    }
  }
  .form-command {
    margin-top: 10px;
  }
  .login {
    margin-top: 10px;
    font-size: small;
  }
  .stacked {
    margin-bottom: 5px;
  }
  .input-group-text {
    height: 100% !important;
  }
  .username {
    font-weight: bold;
  }
  .thanks {
    font-size: large;
    font-weight: bold;
  }
}
</style>