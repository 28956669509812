<template>
  <div class="forgotpassword-page">
    <b-container fluid>
      <b-row class="justify-content-md-center">
        <b-col col lg="4">
          <div v-if="!submitted">
            <div class="page-title">Password Reset</div>
            <b-form @submit="onSubmit">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="stacked"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.username.$model"
                  :state="validateState($v.username)"
                  type="email"
                  placeholder="Enter email"
                  required
                  trim
                  autofocus
                />
              </b-form-group>
              <div class="form-command">
                <b-button type="submit" variant="primary"> Request </b-button>
              </div>
            </b-form>
          </div>
          <div v-if="submitted">
            <div class="page-title">Password Reset</div>
            <div>
              Your password reset has been submitted, you should shortly receive
              an email at <span class="username">{{ username }}</span> with
              instructions on how to reset your password.
            </div>
          </div>
          <div class="login">Return to: <b-link to="Login">Login</b-link></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "ForgotPassword",
  data() {
    return {
      username: null,
      submitted: false,
    };
  },
  validations: {
    username: {
      required,
      email,
    },
  },
  methods: {
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    onSubmit: async function (event) {
      event.preventDefault();
      //Validation
      this.$v.username.$touch();
      if (this.$v.username.$anyError) {
        return false;
      }
      this.$store.commit("setBusy");
      this.$remote.requestReset(this.username).then(()=>{
        this.$store.commit("setUnbusy");
        this.submitted = true;
      });
    },
  },
};
</script>
<style lang="less">
.forgotpassword-page {
  .form-command {
    margin-top: 10px;
  }
  .username {
    font-weight: bold;
  }
  .login {
    margin-top: 10px;
    font-size: small;
  }
}
</style>