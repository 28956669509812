<template>
  <div class="register-page">
    <b-container fluid>
      <b-row class="justify-content-md-center">
        <b-col col lg="4">
          <div v-if="!submitted">
            <div>
              <div class="page-title">Register</div>
              <div v-if="robot" class="error-banner">
                I Think You Are A ROBOT! If I am wrong, try registering again.
              </div>
              <div v-if="denied" class="error-banner">
                Validation of captcha failed. Please refresh page and try again.
              </div>
              <div v-if="exists" class="error-banner">
                Email address already registered.
              </div>
              <b-form @submit="onSubmit">
                <b-form-group
                  id="input-group-1"
                  label-for="input-1"
                  class="stacked"
                >
                  <b-form-input
                    id="input-1"
                    v-model="$v.username.$model"
                    :state="validateState($v.username)"
                    type="email"
                    autocomplete="username"
                    placeholder="Enter email"
                    required
                    trim
                    autofocus
                  />
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label-for="input-2"
                  class="stacked"
                >
                  <b-form-input
                    id="input-2"
                    v-model="$v.fname.$model"
                    :state="validateState($v.fname)"
                    placeholder="First Name"
                    required
                    trim
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-21"
                  label-for="input-21"
                  class="stacked"
                >
                  <b-form-input
                    id="input-21"
                    v-model="$v.lname.$model"
                    :state="validateState($v.lname)"
                    placeholder="Last Name"
                    trim
                    required
                  />
                </b-form-group>
                <b-form-group id="input-group-3" label-for="input-3">
                  <b-form-input
                    id="input-3"
                    v-model="$v.password.$model"
                    :state="validateState($v.password)"
                    type="password"
                    placeholder="Password"
                    autocomplete="new-password"
                    required
                    trim
                    minlength="4"
                    v-on:blur="passLoseFocus"
                    v-on:focus="passGainFocus"
                    pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
                    title="Must contain at least one number and one letter and be at least 8 characters long"
                  />
                  <div v-if="passhelp" class="passhelp">
                    Must contain at least one number and one letter
                    and be at least 8 characters long.
                  </div>
                </b-form-group>

                <b-form-group
                  id="input-group-4"
                  label-for="input-4"
                  class="stacked"
                >
                  <b-form-input
                    id="input-4"
                    v-model="$v.cpassword.$model"
                    :state="validateState($v.cpassword)"
                    type="password"
                    autocomplete="new-password"
                    placeholder="Confirm Password"
                    required
                    trim
                  />
                </b-form-group>
                <b-form-group id="input-group-5" class="robot">
                  <vue-recaptcha
                    ref="recaptcha"
                    @verify="onVerify"
                    sitekey="6Lfi0SocAAAAALD0JiF7LKwPh3862Ox1oJepW_Fl"
                  >
                  </vue-recaptcha>
                </b-form-group>
                <div class="form-command">
                  <b-button type="submit" variant="primary"> Submit </b-button>
                </div>
              </b-form>
            </div>
            <div class="login">
              Already have an account: <b-link to="Login">Login</b-link>
            </div>
          </div>
          <div v-if="submitted">
            <div class="page-title">Register</div>
            <div>
              <div class="thanks">Thank you for Registering!</div>
              You are one step away from using Umangia, you should
              shortly receive an email at
              <span class="username">{{ username }}</span> with instructions on
              how to activate your account.
            </div>
            <div class="login">
              Return to: <b-link to="Login">Login</b-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required, email, minLength,maxLength, sameAs } from "vuelidate/lib/validators";
import { validPassword } from "../../validators/index";
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "Register",
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      robot: false,
      exists: false,
      recapatcha: null,
      username: null,
      fname: null,
      lname: null,
      password: null,
      cpassword: null,
      passhelp: false,
      hasCap: false,
      hasNum: false,
      hasLen: false,
      submitted: false,
      denied: false,
    };
  },
  watch: {
    password: function (val) {
      this.hasCap = /[A-Za-z]/.test(val);
      this.hasNum = /[0-9]/.test(val);
      this.hasLen = val.length >= 8;
    },
  },
  validations: {
    username: {
      required,
      email,
    },
    fname: {
      required,
      maxLength: maxLength(45),
    },
    lname: {
      required,
      maxLength: maxLength(45),
    },
    password: {
      required,
      validPassword,
      minLength: minLength(8),
    },
    cpassword: { required, sameAsPassword: sameAs("password") },
  },
  methods: {
    onVerify: function (response) {
      if (response) {
        this.robot = false;
        this.recapatcha = response;
      } else {
        this.robot = true;
      }
    },
    passLoseFocus() {
      this.passhelp = true;
    },
    passGainFocus() {
      this.passhelp = true;
    },
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    onSubmit: async function (event) {
      event.preventDefault();

      this.denied = false;
      this.exists = false;

      //Validation
      this.$v.fname.$touch();
      this.$v.lname.$touch();
      this.$v.username.$touch();
      this.$v.password.$touch();
      this.$v.cpassword.$touch();
      if (
        this.$v.fname.$anyError ||
        this.$v.lname.$anyError ||
        this.$v.username.$anyError ||
        this.$v.password.$anyError ||
        this.$v.cpassword.$anyError ||
        this.robot
      ) {
        return false;
      }

      var config = {
        error: response => {
          if (response.status == 401) {
            this.denied = true;
            this.$refs.recaptcha.reset();
            this.password = null;
            this.cpassword = null;
          } else if(response.status == 412){
            this.exists = true;
            this.$refs.recaptcha.reset();
            this.password = null;
            this.cpassword = null;
          }else {
            this.$router.push("error");
          }
        }
      };

      this.$store.commit("setBusy");
      this.$remote.register(this.fname,this.lname,this.username,this.password,this.recapatcha,config).then(() => {
        this.submitted = true;
      }).finally(() => {
        this.$store.commit("setUnbusy");
      });
    },
  },
};
</script>
<style lang="less">
.register-page {
  .passhelp {
    padding: 10px 5px 10px 5px;
    font-size: smaller;
    font-weight: bold;
    .status {
      padding: 3px;
      border: 1px solid grey;
      .red {
        color: red;
      }
      .green {
        color: green;
      }
    }
  }
  .robot {
    text-align: center;
  }
  .error-banner {
    color: darkred;
    font-weight: bold;
  }
  .form-command {
    margin-top: 10px;
  }
  .login {
    margin-top: 10px;
    font-size: small;
  }
  .stacked {
    margin-bottom: 5px;
  }
  .input-group-text {
    height: 100% !important;
  }
  .username {
    font-weight: bold;
  }
  .thanks {
    font-size: large;
    font-weight: bold;
  }
}
</style>