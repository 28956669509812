export default (stroke) => {
    // Validate a stroke!!!

    var config =  {
        direction: {
          goodmax: 1.0,
          goodmin: -1.0,
          //warnmax: 0.0,
          //warnmin: -2.0
        },
        impact:{
            goodmax: 1,
            goodmin: -1
        },
        ratio: {
            goodmax: 2.1,
            goodmin: 1.9
        }
      }

    var response = {}

    //Validate Direction
    var dir = stroke.aiAnalysis ? stroke.aiAnalysis.direction : stroke.strokePathAnalysis.direction
    if(dir <= config.direction.goodmax && dir >= config.direction.goodmin){
        response.direction = 0
   // }else if(dir <= config.direction.warnmax && dir >= config.direction.warnmin){
     //   response.direction = 1
    }else{
        response.direction = 2
    }

    //Validate Impact
    var impact = stroke.metricsAnalysis.alignmentAtImpact ? stroke.metricsAnalysis.alignmentAtImpact : stroke.metricsAnalysis.alignAtImpact
    if(impact <= config.impact.goodmax && impact >= config.impact.goodmin){
        response.impact = 0
    }else{
        response.impact = 1
    }

    //Validate Ratio
    var ratio = stroke.metricsAnalysis.backStrokeTime / stroke.metricsAnalysis.foreStrokeTime
    if(ratio <= config.ratio.goodmax && ratio >= config.ratio.goodmin){
        response.ratio = 0
    }else{
        response.ratio = 1
    }

    response.config = config

    return response

}