import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

import Register from '../views/auth/Register.vue'
import Login from '../views/auth/Login.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import Authenticate from '../views/auth/Authenticate'
import Activation from '../views/auth/Activation'
import Account from '../views/account/Account.vue'
import Error from '../views/Error.vue'
import Forbidden from '../views/Forbidden.vue'
import Reset from '../views/auth/Reset'

import Home from '../views/Home'
import Strokes from '../views/measure/Strokes'
import Stroke from '../views/measure/Stroke'
import Training from '../views/training/Training'
import Play from '../views/play/Play'

Vue.use(VueRouter)

const routes = [
  {
    path: '/error',
    name: 'Error',
    component: Error
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: Forbidden
  },
  {
    path: '/activation',
    name: 'Activation',
    component: Activation
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset
  },
  {
    path: '/authenticate',
    name: 'Authenticate',
    component: Authenticate
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/strokes',
    name: 'Strokes',
    component: Strokes,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/stroke/:id',
    name: 'Stroke',
    component: Stroke,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/training',
    name: 'Training',
    component: Training,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/play',
    name: 'Play',
    component: Play,
    meta: {
      requiresAuth: true,
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //User has requested an authenticated page.

    if (store.state.auth && store.state.auth.token) {
      //User is already authenticated. Assume token is good.
      next()
    } else {
      //User is not logged in
      //If the request has a OTP send them to Authenticate else send them to Login
      if (to.query.otp) {
        //User has supplied a OTP via query
        store.commit('setPath', to)
        next({ path: '/authenticate', query: to.query })
      } else {
        //Redirect user to login page
        store.commit('setPath', to)
        next('/login')
      }
    }
  } else {
    //User requested public page, give it to em!
    next()
  }
})

export default router
