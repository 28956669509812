import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: null,
    busy: false,
    path: null,
    strokePage: {}
  },
  mutations: {
    setBusy(state) {
      state.busy = true;
    },
    setUnbusy(state) {
      state.busy = false;
    },
    setAuth(state, payload) {
      state.auth = payload;
    },
    deleteAuth(state) {
      state.auth = null;
    },
    setStrokePage(state, strokePage){
      state.strokePage = strokePage;
    },
    clearStrokes(state){
      state.strokePage = {};
    },
    setPath(state, payload) {
      state.path = payload;
    },
    deletePath(state) {
      state.path = null;
    },
  },
  getters: {
  },
  actions: {
  },
  modules: {
  }
})
