<template>
  <div class="reset-page">
    <b-container fluid>
      <b-row class="justify-content-md-center">
        <b-col col lg="4">
          <div v-if="activated">
            <div class="page-title">Account Activated</div>
            <div>
              <div class="thanks">Your account has been activated!</div>
            </div>
            <div class="login">
              Return to: <b-link to="Login">Login</b-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Activation",
  components: {},
  data() {
    return {
      otp: null,
      activated: false,
    };
  },
  methods: {},
  created: async function () {
    if (this.$route.query.token) {
      this.$store.commit("setBusy");
      this.$store.commit("setAuth", {token: this.$route.query.token});
      this.$remote.activateAccount().then(() => {
        this.$store.commit("setUnbusy");
         this.activated = true;
      });
    } else {
      this.$router.push("error");
    }
  },
};
</script>
<style lang="less">
.reset-page {
  .passhelp {
    padding: 10px 5px 10px 5px;
    font-size: smaller;
    font-weight: bold;
    .status {
      padding: 3px;
      border: 1px solid grey;
      .red {
        color: red;
      }
      .green {
        color: green;
      }
    }
  }
  .form-command {
    margin-top: 10px;
  }
  .login {
    margin-top: 10px;
    font-size: small;
  }
  .stacked {
    margin-bottom: 5px;
  }
  .input-group-text {
    height: 100% !important;
  }
  .username {
    font-weight: bold;
  }
  .thanks {
    font-size: large;
    font-weight: bold;
  }
}
</style>