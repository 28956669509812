<template>
    <span class="metric-value">
        <span class="label">{{ label }}:</span>
        <span :class="validation ? 'err-field value':'value'">{{ value }}{{ unit }}</span>
        <span class="err-field ml-1 cursor-pointer" v-if="validation" v-b-tooltip.html :title="label + ' Should be within range: [' + validationConfig.goodmin + ' to ' + validationConfig.goodmax + ']'"><b-icon-exclamation-triangle></b-icon-exclamation-triangle> </span>
    </span>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: [String,Number],
            required: true
        },
        validationConfig: {
            type: Object,
            required: false
        },
        validation: {
            type: Number,
            required: false
        },
        unit: {
            type: String,
            required: false
        }
    }
}
</script>
<style lang="less">
.metric-value {
    .err-field {
        color: red;
        font-weight: bold;
    }
    .label {
        margin-right: .5em;
    }
}
</style>