<template>
    <div class="home-page">
      <b-container fluid>
        <b-row class="justify-content-md-center">
          <b-col cols="1">
            <b-img fluid src="../assets/golf-ekg-round.png" />
          </b-col>
          <b-col cols="4">
           <b-link to="strokes">Measure</b-link>. The first step in improving your short game is to measure a few strokes and allow our AI and analytics algorithms determine where you need help.
          </b-col>          
        </b-row>
        <b-row class="justify-content-md-center mt-2 mb-2">
          <b-col cols="1">
            <b-img fluid src="../assets/down-arrow3.png" />
          </b-col> 
        </b-row>
        <b-row class="justify-content-md-center">         
          <b-col cols="1">
            <b-img fluid src="../assets/classroom.png" />
          </b-col>
          <b-col cols="4">
            <b-link to="training">Training & Instruction</b-link>. Review the AI analysis of your strokes and use the tools, lesson, technologies, and professional coaching to improve your game.
          </b-col>          
        </b-row>
        <b-row class="justify-content-md-center mt-2 mb-2">
          <b-col cols="1">
            <b-img fluid src="../assets/down-arrow3.png" />
          </b-col> 
        </b-row>
        <b-row class="justify-content-md-center">         
          <b-col cols="1">
            <b-img fluid src="../assets/play.png" />
          </b-col>
          <b-col cols="4">
            <b-link to="play">Put into Practice</b-link>. Take what you have learned out for a spin, record your scores and watch over time as your game improves.
          </b-col>    
        </b-row>
      </b-container>
    </div>
  </template>
  
  <script>
  export default {
    name: "Home",
    components: {},
    data() {
      return {};
    },
    methods: {},
    created: function(){
    }
  };
  </script>
  <style lang="less">
  
  </style>