<template>
  <b-row class="account-page">
    <b-col>
      <b-row>
        <b-col class="text-center page-title">
          Account
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="11" sm="12">
          <b-row> <!-- Password Update -->
            <b-col lg="5" sm="12">
              <b-form-group id="input-group-3" label-for="input-3">
              <b-form-input
                  id="input-3"
                  v-model="$v.password.$model"
                  :state="validateState($v.password)"
                  type="password"
                  placeholder="Password"
                  autocomplete="new-password"
                  required
                  trim
                  minlength="4"
                  v-on:blur="passLoseFocus"
                  v-on:focus="passGainFocus"
                  pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
                  title="Must contain at least one number and one letter and be at least 8 characters long"/>
                <div v-if="passhelp" class="passhelp">
                  Must contain at least one number and one letter
                  and be at least 8 characters long.
                </div>
              </b-form-group>
            </b-col>
            <b-col lg="5" sm="12">
              <b-form-group
                id="input-group-4"
                label-for="input-4"
                class="stacked">
                  <b-form-input
                    id="input-4"
                    v-model="$v.cpassword.$model"
                    :state="validateState($v.cpassword)"
                    type="password"
                    placeholder="Confirm Password"
                    required
                    trim/>
                </b-form-group>
            </b-col>
            <b-col>
              <b-button @click="updatePassword" :disabled="valPassword == false" block variant="success">Save Password</b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="1" sm="12" class="border-left border-dark" >
          <div class="sticky-top controls">        
            <div>
              <b-button @click="close" block variant="primary" >Close</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { validPassword } from "../../validators/index";
export default {
  name: "Account",
  components: {},
  data() {
    return {
      password: null,
      cpassword: null,
      passhelp: false,
    };
  },
  computed: {
     valPassword(){
       if (
        this.$v.password.$invalid ||
        this.$v.cpassword.$invalid 
      ) {
       return false;
      }else{
        return true;
      }
    }
  },
  watch: {
    password: function (val) {
      this.hasCap = /[a-zA-Z]/.test(val);
      this.hasNum = /[0-9]/.test(val);
      this.hasLen = val.length >= 8;
    },
  },
  validations: {
    password: {
      required,
      validPassword,
      minLength: minLength(8),
    },
    cpassword: { required, sameAsPassword: sameAs("password") },
  },
  methods: {
    passLoseFocus() {
      this.passhelp = false;
    },
    passGainFocus() {
      this.passhelp = true;
    },
     validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    updatePassword: function(){
      //Get Busy...
      this.$store.commit("setBusy");

      //Call Upon Rom-Ram and update the password!
      this.$remote.reset(this.password).then(() => {
        //Log The User Out
        this.$remote.logout().then(() =>{
          this.$store.commit("deleteAuth");
          this.$cookies.remove(process.env.VUE_APP_COOKIE);
          this.$store.commit("setUnbusy");
          this.$router.push("Login");
        }).finally(()=>{
          //Stop being busy
          this.$store.commit("setUnbusy");
          this.$router.push('/');
        });
      });

     

    },
    close: function(){
      this.$router.push(this.$store.state.navFrom);
    }
  },
};
</script>
<style lang="less">
.account-page {
   .passhelp {
    padding: 10px 5px 10px 5px;
    font-size: smaller;
    font-weight: bold;
    .status {
      padding: 3px;
      border: 1px solid grey;
      .red {
        color: red;
      }
      .green {
        color: green;
      }
    }
  }
}
</style>