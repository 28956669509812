<template>
  <div class="authenticate-page">
    <b-container fluid>
      <b-row class="justify-content-md-center">
        <b-col col lg="4">
          <div class="page-title">Authenticating</div>
          <div class="content">
            You are being authenticated, please be patient and you will be
            logged in and brought to the website shortly.
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Authenticate",
  components: {},
  data() {
    return {};
  },
  methods: {},
  created() {
    this.$store.commit("setAuth", { token: "ABC", name: "Seamus" });
    var to = this.$store.state.path.path;
    this.$store.commit("deletePath");
    this.$router.push(to);
  },
};
</script>
<style lang="less">
.authenticate-page {
  .placeholder {
    color: red;
  }
}
</style>