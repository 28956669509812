<template>
  <div class="training-page">
    <b-container fluid>
      <b-row>
        <b-col>
          <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center page-title">
          Training & Instruction
        </b-col>
      </b-row>
      <b-row class="justify-content-md-center mb-3">
        <b-col>
          Our AI has analyzed the last 5 strokes you recorded and has determined the characteristics of your putting
          stroke. Below you will find a detailed analysis, Equipment, Technology, Training Videos, and Skill-Drills which will help you
          improve your stroke.
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card no-body>
            <b-tabs card fill>
              <b-tab title="Quad Chart" active>
                <b-card-text>
                  <b-row>
                    <b-col class="text-right">
                      <b-form-checkbox
                        @change="drawCharts()"
                        id="checkbox-1"
                        v-model="showCombined"
                        name="checkbox-1">
                        Show Composite Analysis
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mt-4">
                    <b-col lg="6" sm="12">
                      <highcharts class="hc" :options="pathChartOptions" ref="chart1"></highcharts>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <highcharts class="hc" :options="speedChartOptions" ref="chart2"></highcharts>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" sm="12" class="pt-3">
                      <highcharts class="hc" :options="rollChartOptions" ref="chart3"></highcharts>
                    </b-col>
                    <b-col lg="6" sm="12" class="pt-3">
                      <highcharts class="hc" :options="alignChartOptions" ref="chart4"></highcharts>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>              
              <b-tab title="Metrics">
                <b-card-text>
                  <b-row>
                    <b-col class="text-right">
                      <b-form-checkbox
                        @change="drawCharts()"
                        id="checkbox-1"
                        v-model="showCombined"
                        name="checkbox-1">
                        Show Composite Analysis
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row v-for="(stroke,index) in strokes" :key="stroke.guid" class="mb-5 mt-5">
                    <b-col class="border border-dark">
                      <span v-if="strokes.length>1" class="font-weight-bold mt-1 mb-1">Stroke {{ index + 1 }}</span>
                      <table class="table">
                        <tbody>
                          <tr>
                            <th scope="row">AI</th>
                            <td><metric-value label="BS Length" :value="stroke.strokePathAnalysis.bsLength | round" unit="mm"></metric-value></td>
                            <td><metric-value label="Max Speed" :value="stroke.strokePathAnalysis.speed | round" unit="mm/s"></metric-value></td>
                            <td><metric-value label="Y-Back" :value="stroke.strokePathAnalysis.insideOutside | round" unit="mm"></metric-value></td>
                            <td><metric-value :validation="stroke.validation.direction" :validationConfig="stroke.validation.config.direction" label="Dir. @ Impact" :value="stroke.strokePathAnalysis.direction | round(2)" unit="°"></metric-value></td>
                          </tr>
                          <tr>
                            <th>Timing</th>
                            <td><metric-value label="Total" :value="stroke.metricsAnalysis.totalstrokeTime" unit="ms"></metric-value></td>
                            <td><metric-value label="Backstroke" :value="stroke.metricsAnalysis.backstrokeTime" unit="ms"></metric-value></td>
                            <td><metric-value label="Forestroke" :value="stroke.metricsAnalysis.forestrokeTime" unit="ms"></metric-value></td>
                            <td><metric-value :validation="stroke.validation.ratio" :validationConfig="stroke.validation.config.ratio" label="Ratio" :value="stroke.metricsAnalysis.strokeTimingRatio"></metric-value></td>                                
                          </tr>
                          <tr>
                            <th>Alignment</th>
                            <td><metric-value label="Address" :value="stroke.metricsAnalysis.alignAtAddress | numeral('0.00')" unit="°"></metric-value></td>
                            <td><metric-value label="Backstroke" :value="stroke.metricsAnalysis.alignAtBackstroke | numeral('0.00')" unit="°"></metric-value></td>
                            <td><metric-value :validation="stroke.validation.impact" :validationConfig="stroke.validation.config.impact" label="Impact" :value="stroke.metricsAnalysis.alignAtImpact | numeral('0.00')" unit="°"></metric-value></td>
                            <td><metric-value label="End" :value="stroke.metricsAnalysis.alignAtEnd | numeral('0.00')" unit="°"></metric-value></td>
                          </tr>
                        </tbody>
                      </table>

                    </b-col> 
                  </b-row>
                </b-card-text>
              </b-tab>
              <b-tab title="Instruction">
                <b-row class="mt-4">
              <b-col class="h4 text-left">
                Training Videos
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-row>
                  <!--<b-col v-for="video in analysis.training.videos" :key="video.id" cols="1"><b-img fluid src="../../assets/videos/video1.jpg" /></b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col class="h4 text-left">
                Skill Drills
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-row>
                  <!--<b-col v-for="drill in analysis.training.drills" :key="drill.id" cols="1"><b-img fluid src="../../assets/skill-drill.png" /></b-col>-->
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col class="h4 text-left">
                Equipment & Technology
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-row>
                  <!--<b-col v-for="product in analysis.training.products" :key="product.id" cols="1"><b-img fluid src="../../assets/swing-sound.png" /></b-col>-->
                </b-row>
              </b-col>
            </b-row>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>      
    </b-container>
  </div>
</template>
  
<script>
import strokeValidator from '../../validation'
import MetricValue from '../../components/MetricValue.vue'
export default {
  name: "Training",
  components: {
    MetricValue
  },
  data() {
    return {
      analysis: {},
      strokes: [],
      showCombined:true,
      breadcrumbs: [
        {
          text: 'Home',
          to: { path: '/' }
        },
        {
          text: 'Training & Instruction',
          active: true
        }
      ],
      pathChartOptions: {
        tooltip: { enabled: false },
        title: {
          text: 'Stroke Direction'
        },
        xAxis: {
          visible: false,
          max:0,
          min:-1000
        },
        yAxis: {
          visible: false,
          min: -500,
          max: 500
          //title: { text: "" }
        },
        chart: {
          type: 'area',
        },
        series: []
      },
      speedChartOptions: {
        tooltip: { enabled: false },
        title: {
          text: 'Stroke Speed'
        },
        xAxis: {
          visible: true,
          plotLines: [
            {
              color: '#00FF00',
              width: 1,
              value: 101,
              label: { 
                text: 'Backstroke End',
                rotation: 0,
                verticalAlign: 'top' 
              }
            },
            {
              color: '#000000',
              width: 1,
              value: 202,
              label: { 
                text: 'Impact',
                rotation: 0,
                verticalAlign: 'top' 
              }
            }
          ]
        },
        yAxis: {
          visible: true,
          title: { text: "millimeters per Second" }
        },
        chart: {
          type: 'spline',
        },
        series: []
      },
      rollChartOptions: {
        tooltip: { enabled: false },
        title: {
          text: 'Roll'
        },
        xAxis: {
          visible: true,
          plotLines: [
            {
              color: '#00FF00',
              width: 1,
              value: 101,
              label: { 
                text: 'Backstroke End',
                rotation: 0,
                verticalAlign: 'top' 
              }
            },
            {
              color: '#000000',
              width: 1,
              value: 202,
              label: { 
                text: 'Impact',
                rotation: 0,
                verticalAlign: 'top' 
              }
            }
          ]
        },
        yAxis: {
          visible: true,
          title: { text: "Degrees" }
        },
        chart: {
          type: 'spline',
        },
        series: []
      },
      alignChartOptions: {
        tooltip: { enabled: false },
        title: {
          text: 'Alignment'
        },
        xAxis: {
          visible: true,
          plotLines: [
            {
              color: '#00FF00',
              width: 1,
              value: 101,
              label: { 
                text: 'Backstroke End',
                rotation: 0,
                verticalAlign: 'top' 
              }
            },
            {
              color: '#000000',
              width: 1,
              value: 202,
              label: { 
                text: 'Impact',
                rotation: 0,
                verticalAlign: 'top' 
              }
            }
          ]
        },
        yAxis: {
          max: 12,
          min: -12,
          visible: true,
          title: { text: "Degrees" }
        },
        chart: {
          type: 'spline',
        },
        series: []
      }
    };
  },
  methods: {
    findFrameByAttribute: function (attribute, value) {
      var frame;
      this.stroke.frameAnalysis.forEach(element => {
        if (element[attribute] == value) {
          frame = element;
        }
      });
      if (!frame) {
        throw 'No Frame Found.'
      } else {
        return frame;
      }
    },
    maxBetweenPoints(start, end,frames, field) {
      var max = 0, maxIndex = start;
      for (let i = start; i <= end; i++) {
        var e = frames[i];
        if (max <= e[field]) {
          max = e[field];
          maxIndex = i;
        }
      }
      return maxIndex;
    },
    drawSpeedChart: function () {

      this.speedChartOptions.series.length = 0

      var strokes = [];

      if(this.showCombined){
        strokes.push(this.analysis.combined)
      }else{
        strokes =this.analysis.uncombined
      }

      strokes.forEach((stroke,sIndex) => {
        var speedSeries = { data: [], name: 'Stroke '+ (sIndex +1), animation: false, showInLegend: !this.showCombined, enableMouseTracking: false, marker: { enabled: false } };
        
        //Find stroke events.
        var s = -1;
        var bse = -1;
        var impact = -1;
        var end = -1;
        
        stroke.frames.forEach((element, index) => {
          if (element.event == "S") {
            s = index;
          }
          if (element.event == "BSE") {
            bse = index
          }

          if (element.event == "FSE") {
            end = index;
          }

          if (element.event == "I") {
            impact = index;
          }
          element.time = index
        
        });

        //Speed Line Series
        var i = this.maxBetweenPoints(s, bse, stroke.frames, 'velocity');
        var e = stroke.frames[i];
        speedSeries.data.push([stroke.frames[s].time, stroke.frames[s].velocity])
        speedSeries.data.push([e.time, e.velocity])
        speedSeries.data.push([stroke.frames[bse].time, stroke.frames[bse].velocity])
        speedSeries.data.push([stroke.frames[impact].time, stroke.frames[impact].velocity])
        speedSeries.data.push([stroke.frames[end].time, stroke.frames[end].velocity])

        //Draw All Series.
        this.speedChartOptions.series.push(speedSeries);
      })

    },
    drawRollChart: function () {
      this.rollChartOptions.series.length = 0

      var strokes = [];

      if(this.showCombined){
        strokes.push(this.analysis.combined)
      }else{
        strokes =this.analysis.uncombined
      }
     
      strokes.forEach((stroke,sIndex) => {
        var rollSeries = { data: [], name: 'Stroke '+ (sIndex +1),animation: false, showInLegend: !this.showCombined, enableMouseTracking: false, marker: { enabled: false } };
       
        //Find stroke events.
        var s = -1;
        var bse = -1;
        var impact = -1;
        var end = -1;
        
        stroke.frames.forEach((element, index) => {
          if (element.event == "S") {
            s = index;
          }
          if (element.event == "BSE") {
            bse = index
          }

          if (element.event == "FSE") {
            end = index;
          }

          if (element.event == "I") {
            impact = index;
          }
          element.time = index
        
        });

        //Speed Line Series
        var i = this.maxBetweenPoints(s, bse, stroke.frames, 'roll');
        var e = stroke.frames[i];
        rollSeries.data.push([stroke.frames[s].time, stroke.frames[s].roll])
        rollSeries.data.push([e.time, e.roll])
        rollSeries.data.push([stroke.frames[bse].time, stroke.frames[bse].roll])
        rollSeries.data.push([stroke.frames[impact].time, stroke.frames[impact].roll])
        rollSeries.data.push([stroke.frames[end].time, stroke.frames[end].roll])

        //Draw All Series.
        this.rollChartOptions.series.push(rollSeries);
      })
    },
    drawPathChart: function(){
      this.pathChartOptions.series.length = 0
     // if(this.showCombined){
        var pathSeries = { data: [], color: '#FF0000', animation: false, showInLegend: false, enableMouseTracking: false, marker: { enabled: false } }
        var baseSeries = { data: [[0,0],[-1000,0]], lineWidth: 1, animation: false, showInLegend: false, enableMouseTracking: false, marker: { enabled: false } }
        var lineSeries = { data: [[0,0]], type: "line", color: '#000000', animation: false, showInLegend: false, enableMouseTracking: false, marker: { enabled: false } }
        pathSeries.data.push([0,0])
        
        var val = -1000 * (Math.tan(Math.abs(this.analysis.combined.strokePathAnalysis.direction)* Math.PI / 180))
        pathSeries.data.push([-1000,val])
        lineSeries.data.push([-1000,val])

        if(this.analysis.combined.validation.direction == 0){
          pathSeries.color = '#00FF00'
        }else{
          pathSeries.color = '#FF0000'
        }

        this.pathChartOptions.series.push(pathSeries)
        this.pathChartOptions.series.push(lineSeries)    
        this.pathChartOptions.series.push(baseSeries)  
      /*}else{
        this.analysis.uncombined.forEach((stroke,sIndex) => {
          var baseSeries = { data: [[0,0],[-1000,0]], lineWidth: 1, animation: false, showInLegend: false, enableMouseTracking: false, marker: { enabled: false } }
          var lineSeries = { data: [[0,0]], type: "line", name: 'Stroke '+ (sIndex +1), animation: false, showInLegend: true, enableMouseTracking: false, marker: { enabled: false } }

          var val = -1000 * (Math.tan(Math.abs(stroke.strokePathAnalysis.direction)* Math.PI / 180))
          
          lineSeries.data.push([-1000,val])

          this.pathChartOptions.series.push(lineSeries)    
          this.pathChartOptions.series.push(baseSeries)  
        })
      }*/
    },
    drawAlignChart: function () {     
      this.alignChartOptions.series.length = 0

      var strokes = [];

      if(this.showCombined){
        strokes.push(this.analysis.combined)
      }else{
        strokes =this.analysis.uncombined
      }

      strokes.forEach((stroke,sIndex) => {
        var alignSeries = { data: [], name: 'Stroke '+ (sIndex +1),animation: false, showInLegend: !this.showCombined, enableMouseTracking: false, marker: { enabled: false } };
      
        //Find stroke events.
        var s = -1;
        var bse = -1;
        var impact = -1;
        var end = -1;
        
        stroke.frames.forEach((element, index) => {
          if (element.event == "S") {
            s = index;
          }
          if (element.event == "BSE") {
            bse = index
          }

          if (element.event == "FSE") {
            end = index;
          }

          if (element.event == "I") {
            impact = index;
          }
          element.time = index
        
        });

        //Speed Line Series
        var i = this.maxBetweenPoints(s, bse, stroke.frames, 'yaw');
        var e = stroke.frames[i];
        alignSeries.data.push([stroke.frames[s].time, stroke.frames[s].yaw])
        alignSeries.data.push([e.time, e.yaw])
        alignSeries.data.push([stroke.frames[bse].time, stroke.frames[bse].yaw])
        alignSeries.data.push([stroke.frames[impact].time, stroke.frames[impact].yaw])
        alignSeries.data.push([stroke.frames[end].time, stroke.frames[end].yaw])

        //Draw All Series.
        this.alignChartOptions.series.push(alignSeries);
      })
    },
    drawCharts: function () {
      this.drawSpeedChart()
      this.drawRollChart()
      this.drawAlignChart()
      this.drawPathChart()

      this.strokes.length = 0
      if(this.showCombined){
        this.strokes.push(this.analysis.combined)
      }else{
        this.analysis.uncombined.forEach(stroke => {
          this.strokes.push(stroke)
        })
      }

    },
  },
  created: async function () {
    this.$store.commit("setBusy");

    const response = await this.$remote.getAnalysis();

    this.$store.commit("setUnbusy");

    if (response.status == 200) {
      this.analysis = response.data

      this.analysis.combined.validation = strokeValidator(this.analysis.combined, this.validation)

      this.analysis.uncombined.forEach(stroke => {
        stroke.validation = strokeValidator(stroke, this.validation)
        this.strokes.push(stroke)
      })

      this.drawCharts()
    } else {
      this.$router.push("error");
    }

  }
};
</script>
<style lang="less">
.err-fieldz {
  font-weight: bold;
  border: 1px solid;
  border-color: red;
  background: repeating-linear-gradient(
    45deg,
    #db5252,
    #db5252 10px,
    #e4b4b4 10px,
    #e4b4b4 20px
  );
}
</style>