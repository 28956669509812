<template>
  <div class="play-page">
    <b-container fluid>
      <b-row>
        <b-col>
          <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center page-title">
          View Stroke
        </b-col>
      </b-row>
      <b-row v-if="stroke"><b-col>
          <b-row>
            <b-col class="text-center">
              {{ stroke.created | moment("MMM D, YYYY HH:mm:ss") }} <span v-if="stroke.label"> - {{ stroke.label }}</span>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-card no-body>
                <b-tabs card fill>
                  <b-tab title="Quad Chart" active>
                    <b-card-text>
                      <b-row class="mt-4">
                        <b-col lg="6" sm="12">
                          <highcharts class="hc" :options="pathChartOptions" ref="chart1"></highcharts>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <highcharts class="hc" :options="speedChartOptions" ref="chart2"></highcharts>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="12" class="pt-3 border-right border-dark">
                          <highcharts class="hc" :options="rollChartOptions" ref="chart3"></highcharts>
                        </b-col>
                        <b-col lg="6" sm="12" class="pt-3">
                          <highcharts class="hc" :options="alignChartOptions" ref="chart4"></highcharts>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Metrics">
                    <b-card-text>
                      <b-row class="mb-5 mt-5">
                        <b-col class="border border-dark">
                          <table class="table">
                            <tbody>
                              <tr>
                                <th scope="row">AI</th>
                                <td><metric-value label="BS Length" :value="stroke.aiAnalysis.backstrokeLength | round" unit="mm"></metric-value></td>
                                <td><metric-value label="Max Speed" :value="stroke.aiAnalysis.speed | round" unit="mm/s"></metric-value></td>
                                <td><metric-value label="Y-Back" :value="stroke.aiAnalysis.insideOutside | round" unit="mm"></metric-value></td>
                                <td><metric-value :validation="stroke.validation.direction" :validationConfig="stroke.validation.config.direction" label="Dir. @ Impact" :value="stroke.aiAnalysis.direction | round(2)" unit="°"></metric-value></td>
                              </tr>
                              <tr>
                                <th>Timing</th>
                                <td><metric-value label="Total" :value="stroke.metricsAnalysis.totalstrokeTime" unit="ms"></metric-value></td>
                                <td><metric-value label="Backstroke" :value="stroke.metricsAnalysis.backstrokeTime" unit="ms"></metric-value></td>
                                <td><metric-value label="Forestroke" :value="stroke.metricsAnalysis.forestrokeTime" unit="ms"></metric-value></td>
                                <td><metric-value :validation="stroke.validation.ratio" :validationConfig="stroke.validation.config.ratio" label="Ratio" :value="stroke.metricsAnalysis.timingRatio"></metric-value></td>                                
                              </tr>
                              <tr>
                                <th>Alignment</th>
                                <td><metric-value label="Address" :value="stroke.metricsAnalysis.alignmentAtAddress | numeral('0.00')" unit="°"></metric-value></td>
                                <td><metric-value label="Backstroke" :value="stroke.metricsAnalysis.alignmentAtBackstroke | numeral('0.00')" unit="°"></metric-value></td>
                                <td><metric-value :validation="stroke.validation.impact" :validationConfig="stroke.validation.config.impact" label="Impact" :value="stroke.metricsAnalysis.alignmentAtImpact | numeral('0.00')" unit="°"></metric-value></td>
                                <td><metric-value label="End" :value="stroke.metricsAnalysis.alignmentAtEnd | numeral('0.00')" unit="°"></metric-value></td>
                              </tr>
                            </tbody>
                          </table>

                        </b-col>                       
                      </b-row>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-col>
          </b-row>
        </b-col></b-row>
    </b-container>
  </div>
</template>
  
<script>
import strokeValidator from '../../validation'
import MetricValue from '../../components/MetricValue.vue'
export default {
  name: "Stroke",
  components: {
    MetricValue
  },
  data() {
    return {
      stroke: null,
      breadcrumbs: [
        {
          text: 'Home',
          to: { path: '/' }
        },
        {
          text: 'Strokes',
          to: { path: '/strokes' }
        },
        {
          text: 'View Stroke',
          active: true
        }
      ],
      pathChartOptions: {
        tooltip: { enabled: false },
        title: {
          text: 'Stroke Direction'
        },
        xAxis: {
          visible: false,
          max:0,
          min:-1000
        },
        yAxis: {
          visible: false,
          min: -500,
          max: 500
          //title: { text: "" }
        },
        chart: {
          type: 'area',
        },
        series: []
      },
      speedChartOptions: {
        tooltip: { enabled: false },
        title: {
          text: 'Stroke Speed'
        },
        xAxis: {
          visible: true
        },
        yAxis: {
          visible: true,
          title: { text: "millimeters per Second" }
        },
        chart: {
          type: 'spline',
        },
        series: []
      },
      rollChartOptions: {
        tooltip: { enabled: false },
        title: {
          text: 'Roll'
        },
        xAxis: {
          visible: true
        },
        yAxis: {
          max: 4,
          min: -2,
          visible: true,
          title: { text: "Degrees" }
        },
        chart: {
          type: 'spline',
        },
        series: []
      },
      alignChartOptions: {
        tooltip: { enabled: false },
        title: {
          text: 'Alignment'
        },
        xAxis: {
          visible: true,
        },
        yAxis: {
          max: 12,
          min: -12,
          visible: true,
          title: { text: "Degrees" }
        },
        chart: {
          type: 'spline',
        },
        series: []
      },
      validationResult:{}
    };
  },
  methods: {
    findFrameByAttribute: function (attribute, value) {
      var frame;
      this.stroke.frameAnalysis.forEach(element => {
        if (element[attribute] == value) {
          frame = element;
        }
      });
      if (!frame) {
        throw 'No Frame Found.'
      } else {
        return frame;
      }
    },
    maxBetweenPoints(start, end, field) {
      var max = 0, maxIndex = start;
      for (let i = start; i <= end; i++) {
        var e = this.stroke.frameAnalysis[i];
        if (max <= e[field]) {
          max = e[field];
          maxIndex = i;
        }
      }
      return maxIndex;
    },
    drawSpeedChart: function () {
      var speedSeries = { data: [], animation: false, showInLegend: false, enableMouseTracking: false, marker: { enabled: false } };
      var impactSeries = { data: [], animation: false, showInLegend: true, name: "Impact", enableMouseTracking: false, lineWidth: 8 };
      var bseSeries = { data: [], animation: false, showInLegend: true, name: "BackStoke End", enableMouseTracking: false, lineWidth: 8 };
      var s = -1;
      var bse = -1;
      var impact = -1;
      var end = -1;
      this.stroke.frameAnalysis.forEach((element, index) => {
        if (element.event == "S") {
          s = index;
        }
        if (element.event == "BSE") {
          bse = index
          //bseSeries.data.push([element.timestamp,element.agy])
        }

        if (element.event == "FSE") {
          end = index;
        }

        if (element.event == "I") {
          impact = index;
          //impactSeries.data.push([element.timestamp,element.agy])
        }
      });

      var i = this.maxBetweenPoints(s, bse, 'velocity');
      var e = this.stroke.frameAnalysis[i];
      speedSeries.data.push([this.stroke.frameAnalysis[s].timestamp, this.stroke.frameAnalysis[s].velocity])
      speedSeries.data.push([e.timestamp, e.velocity])
      speedSeries.data.push([this.stroke.frameAnalysis[bse].timestamp, this.stroke.frameAnalysis[bse].velocity])
      bseSeries.data.push([this.stroke.frameAnalysis[bse].timestamp, this.stroke.frameAnalysis[bse].velocity])

      //impact = this.maxBetweenPoints(bse,end,'agy');

      speedSeries.data.push([this.stroke.frameAnalysis[impact].timestamp, this.stroke.frameAnalysis[impact].velocity])
      impactSeries.data.push([this.stroke.frameAnalysis[impact].timestamp, this.stroke.frameAnalysis[impact].velocity])
      speedSeries.data.push([this.stroke.frameAnalysis[end].timestamp, this.stroke.frameAnalysis[end].velocity])

      this.speedChartOptions.series.push(speedSeries);
      this.speedChartOptions.series.push(impactSeries);
      this.speedChartOptions.series.push(bseSeries);
    },
    drawRollChart: function () {
      var impactSeries = { data: [], animation: false, showInLegend: true, name: "Impact", enableMouseTracking: false, lineWidth: 8 };
      var bseSeries = { data: [], animation: false, showInLegend: true, name: "BackStoke End", enableMouseTracking: false, lineWidth: 8 };
      var rollSeries = { data: [], animation: false, showInLegend: false, enableMouseTracking: false, marker: { enabled: false } };

      this.stroke.frameAnalysis.forEach(element => {
        rollSeries.data.push([element.timestamp, element.roll])
        if (element.event == "I") {
          impactSeries.data.push([element.timestamp, element.roll])
        } else if (element.event == "BSE") {
          bseSeries.data.push([element.timestamp, element.roll])
        }
      });
      this.rollChartOptions.series.push(rollSeries);
      this.rollChartOptions.series.push(impactSeries);
      this.rollChartOptions.series.push(bseSeries);
    },
    drawPathChart: function(){
      var pathSeries = { data: [], color: '#FF0000', animation: false, showInLegend: false, enableMouseTracking: false, marker: { enabled: false } }
      var baseSeries = { data: [[0,0],[-1000,0]], lineWidth: 1, animation: false, showInLegend: false, enableMouseTracking: false, marker: { enabled: false } }
      var lineSeries = { data: [[0,0]], type: "line", color: '#000000', animation: false, showInLegend: false, enableMouseTracking: false, marker: { enabled: false } }

      pathSeries.data.push([0,0])
    

      var val = -1000 * (Math.tan(Math.abs(this.stroke.aiAnalysis.direction)* Math.PI / 180))

      pathSeries.data.push([-1000,val])
      lineSeries.data.push([-1000,val])

      if(this.stroke.validation.direction == 0){
        pathSeries.color = '#00FF00'
      }else if(this.stroke.validation.direction > 0){
        pathSeries.color = '#FF0000'
      }//else{
       // pathSeries.color = '#FF0000'
      //}

      this.pathChartOptions.series.push(pathSeries)
      this.pathChartOptions.series.push(lineSeries)    
      this.pathChartOptions.series.push(baseSeries)  
    },
    drawAlignChart: function () {
      var impactSeries = { data: [], animation: false, showInLegend: true, name: "Impact", enableMouseTracking: false, lineWidth: 8 };
      var bseSeries = { data: [], animation: false, showInLegend: true, name: "BackStoke End", enableMouseTracking: false, lineWidth: 8 };
      var alignSeries = { data: [], animation: false, showInLegend: false, enableMouseTracking: false, marker: { enabled: false } };

      this.stroke.frameAnalysis.forEach(element => {
        alignSeries.data.push([element.timestamp, element.yaw])

        if (element.event == "I") {
          impactSeries.data.push([element.timestamp, element.yaw])
        } else if (element.event == "BSE") {
          bseSeries.data.push([element.timestamp, element.yaw])
        }
      });
      this.alignChartOptions.series.push(alignSeries);
      this.alignChartOptions.series.push(impactSeries);
      this.alignChartOptions.series.push(bseSeries);
    },
  },
  created: async function () {
    this.$store.commit("setBusy");

    const response = await this.$remote.getStroke(this.$route.params.id);

    this.$store.commit("setUnbusy");

    if (response.status == 200) {
      this.stroke = response.data
      this.stroke.validation = strokeValidator(this.stroke)
      this.drawPathChart()
      this.drawSpeedChart()
      this.drawRollChart()
      this.drawAlignChart()
     
    } else {
      this.$router.push("error");
    }

  }
};
</script>
<style lang="less">
</style>