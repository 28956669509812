import Vue from 'vue'
import App from './App.vue'
import router from './router'
import remote from './remote'
import Vuelidate from 'vuelidate'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
//import HighchartsVue from 'highcharts-vue'
import vueNumeralFilterInstaller from 'vue-numeral-filter'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsVue from 'highcharts-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(Vuelidate)
HighchartsMore(Highcharts)
Vue.use(HighchartsVue)
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(require('vue-cookies'));
Vue.use(require('vue-moment'));

Vue.$remote = remote;

Object.defineProperty(Vue.prototype, '$remote', {
  get () {
    return remote
  }
})

Vue.config.productionTip = false

/** Utility Shims */
Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

Vue.filter('round', function(value, decimals) {
  if(!value) {
    value = 0;
  }

  if(!decimals) {
    decimals = 0;
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
